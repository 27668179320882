import React from "react";
import hands2 from "../img/hands2.jpg";

import { Newsletter } from "./Newsletter";

import { Button, TextField } from "@mui/material";

import volunteers from "../img/volunteers.jpg";
import { useTranslation } from "react-i18next";

export const GetInvolved = () => {
  const { i18n } = useTranslation();

  const [details, setDetails] = React.useState({
    textOverImage: "",
    volunteeringSection: {
      title: "",
      description: "",
      callToActionMessage: "",
      buttonLabel: "",
    },
    redirect20: {
      title: "",
      description: "",
    },
  });

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/get-involved?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        const { attributes } = data;

        setDetails(attributes);
      });
  }, [i18n.language]);

  return (
    <div className="get-involved-page">
      <div className="hero-section">
        {/* <img src={hands2} /> */}
        <div className="hero-section-bg-image" />
        <div className="message">
          {details.textOverImage}
        </div>
      </div>

      <div className="volunteer-wrapper">
        <div className="title">
          {details.volunteeringSection.title}
        </div>
        <div className="description">
          {details.volunteeringSection.description}
        </div>
        <div className="form-wrapper">
          <div className="image-wrapper">
            {/* <img src={volunteers} /> */}
          </div>
          {/* <div className="other-wrapper"> */}
          <div className="form-cta-wrapper">
            {
              details.volunteeringSection
                .callToActionMessage
            }
          </div>
          <div className="form-input-wrapper">
            <TextField
              placeholder="email"
              variant="outlined"
              label="email"
              size="small"
              className="email-input"
            />
            <Button
              variant="contained"
              sx={{
                background: "#FFFFFF !important",
                color: "black",
                textTransform: "none",

                height: "40px",
              }}
              className="submit-button"
            >
              {details.volunteeringSection.buttonLabel}
            </Button>
          </div>
          {/* <div className="form-submit-button-wrapper">
            
          </div> */}

          {/* </div> */}
        </div>
      </div>

      <Redirect20
        title={details.redirect20.title}
        description={details.redirect20.description}
      />

      {/* <Newsletter /> */}
    </div>
  );
};

const Redirect20 = ({ title, description }) => {
  return (
    <div className="redirect-20">
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </div>
  );
};
