import React from "react";

import whatWeDo from "../img/what-we-do.jpg";

import o1 from "../img/o1.jpg";
import o2 from "../img/o2.jpg";
import o3 from "../img/o3.jpg";
import o4 from "../img/o4.jpg";
import o5 from "../img/o5.jpg";
import o6 from "../img/o6.jpg";

import { Newsletter } from "./Newsletter";
import { useTranslation } from "react-i18next";

// import mask from "../img/mask1.png";

export const WhatWeDo = () => {
  const [textOverImage, setTextOverImage] =
    React.useState("");
  const [textDescription, setTextDescription] =
    React.useState("");
  const [objectives, setObjectives] = React.useState([]);

  const { i18n } = useTranslation();

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/what-we-do?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        const {
          attributes: {
            textOverImage,
            textDescription,
            objectives,
          },
        } = data;

        setTextOverImage(textOverImage);
        setTextDescription(textDescription);
        setObjectives(objectives);
      });
  }, [i18n.language]);

  return (
    <div className="what-we-do-page">
      <div className="hero-section">
        <div className="hero-section-bg-image" />

        <div className="message">{textOverImage}</div>
      </div>

      <div className="page-title-section">
        {textDescription}
      </div>

      <div className="objectives-wrapper">
        {objectives.map(({ title, bulletItems }, index) => {
          return (
            <ObjectiveItem
              title={title}
              bulletItems={bulletItems}
              index={index + 1}
              key={index}
              imagePosition={
                index % 2 === 0 ? "right" : "left"
              }
            />
          );
        })}
      </div>
      {/* <Newsletter /> */}
    </div>
  );
};

const ObjectiveItem = (props) => {
  const {
    title,
    image,
    bulletItems,
    imagePosition,
    index,
  } = props;

  return (
    <div
      className={`objective-item-wrapper ${
        imagePosition === "left"
          ? "image-first"
          : "image-last"
      }
      ${imagePosition}-image-position`}
      //   style={
      //     imagePosition === "left"
      //       ? { paddingLeft: 0 }
      //       : { paddingRight: 0 }
      //   }
    >
      <div className="title">{`${title}`}</div>
      <div className="image">
        <div className="image-mask">
          <div
            className={`bg-image-section objective-img-${index}`}
          />
          {/* <img src={image} /> */}
        </div>
      </div>
      <div className="description">
        {bulletItems.map(({ bulletItem }, index) => (
          <div className="paragraph" key={index}>
            <li>{bulletItem}</li>
          </div>
        ))}
      </div>
    </div>
  );
};
