import React from "react";

import facebook from "../img/facebook.svg";
import instagram from "../img/instagram.svg";
import linkedin from "../img/linkedin.svg";

import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import newLogo from "../img/logo2.png";

export const Footer = () => {
  const [footerDetails, setFooterDetails] = React.useState({
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    line5: "",
    line6: "",
    phoneNumber: {
      title: "",
      value: "",
    },
    email: {
      title: "",
      value: "",
    },
    buttonLabel: "",
  });

  const { i18n } = useTranslation();

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/footer?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        const { attributes } = data;

        setFooterDetails(attributes);
      });
  }, [i18n.language]);

  const navigate = useNavigate();

  return (
    <>
      {/* <svg width="0" height="0">
        <defs>
          <clipPath
            id="wave"
            clipPathUnits="objectBoundingBox"
          >
            <path
              d="M 0,0
                C 0.15,0.9 0.35,0.7 0.5,0.8
                C 0.65,0.9 0.85,0.7 1,0.8
                L 1,1
                L 0,1
                Z"
            ></path>
          </clipPath>
        </defs>
      </svg> */}

      <div className="footer-wrapper">
        <div className="footer-background-gradient" />

        {/* <div className="footer-logo-wrapper">
          <img src={newLogo} />
        </div> */}

        <div className="footer-contact-bank-details">
          <div>{footerDetails.line1}</div>
          <div>{footerDetails.line2}</div>
          <div> {footerDetails.line3}</div>
          <div> {footerDetails.line4}</div>
          <div> {footerDetails.line5}</div>
          <div>{footerDetails.line6}</div>
        </div>
        <div className="footer-contact-wrapper">
          <div>
            <div style={{ fontWeight: "bold" }}>
              {footerDetails.phoneNumber.title}
            </div>
            <div>{footerDetails.phoneNumber.value}</div>
          </div>
          <div>
            <div
              style={{
                fontWeight: "bold",
              }}
            >
              {footerDetails.email.title}
            </div>
            <div> {footerDetails.email.value}</div>
          </div>
        </div>
        {/* <div className="footer-social-media-wrapper"> */}
        <div className="donate-button-wrapper">
          {/* <div className="donate-button">Doneaza</div> */}
          <Button
            variant="contained"
            sx={{
              background: "#FFFFFF !important",
              color: "#b99095",
              fontWeight: "bold",
              textTransform: "none",
              padding: "28px 50px",
              height: "40px",
              // boxShadow: "none",
              boxShadow: "2px 2px 10px #5e5e5e",
            }}
            size="large"
          >
            {footerDetails.buttonLabel}
          </Button>
        </div>
        <div className="social-media-wrapper">
          <div
            className="social-media-item-wrapper"
            onClick={() => {
              window.open("https://facebook.com", "_BLANK");
            }}
          >
            <img
              src={facebook}
              className="social-media-item"
            />
          </div>
          <div
            className="social-media-item-wrapper"
            onClick={() => {
              window.open(
                "https://www.instagram.com/mindcareforall",
                "_BLANK"
              );
            }}
          >
            <img
              src={instagram}
              className="social-media-item"
            />
          </div>
          <div
            className="social-media-item-wrapper"
            onClick={() => {
              window.open(
                "https://www.linkedin.com/in/mindcare4all-54430b310/",
                "_BLANK"
              );
            }}
          >
            <img
              src={linkedin}
              className="social-media-item"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <div
            onClick={() =>
              navigate("/terms-and-conditions")
            }
            style={{
              fontSize: "14px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            Termeni si conditii
          </div>
          <div
            onClick={() =>
              navigate("/confidentiality-policy")
            }
            style={{
              fontSize: "14px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            Politica de confidentialitate
          </div>
        </div>
      </div>
    </>
  );
};
