import React from "react";
import { Box, AppBar } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from "./Menu";
import logo from "../img/logo2.svg";
import newLogo from "../img/logo2.png";
import { Footer } from "./Footer";
import { useTranslation } from "react-i18next";

import { initGA, logPageView } from "../gtag";

import { CookiesConsentModal } from "./CookiesConsentModal";

export const Page = (props) => {
  const { fetchURL, component: Component } = props;

  const { i18n } = useTranslation();
  React.useEffect(() => {
    const cookieConsent = localStorage.getItem(
      "cookie-consent"
    );

    const cookieConsentIgnored = localStorage.getItem(
      "cookie-consent-ignored"
    );

    if (
      cookieConsentIgnored &&
      (Date.now() - parseInt(cookieConsentIgnored)) / 1000 >
        60 * 5
    ) {
      localStorage.removeItem("cookie-consent-ignored");
    }

    if (cookieConsent === "yes") {
      initGA("G-ZZC49NW971"); // Replace with your GA4 Tracking ID
    }
  }, [Component]);

  return (
    <>
      <CookiesConsentModal Component={Component} />
      <RouteChangeTracker />
      <div className="page-top-menu-wrapper">
        <LogoImg />
        <Menu />
      </div>

      <Component />
      <Footer />
    </>
  );
};

const LogoImg = () => {
  const navigate = useNavigate();
  return (
    <div className="logo-wrapper">
      <img
        className="page-logo"
        src={newLogo}
        onClick={() => navigate("/")}
      />
    </div>
  );
};

const RouteChangeTracker = () => {
  const location = useLocation();

  React.useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};
