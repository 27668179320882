import React from "react";
import { Box, Button } from "@mui/material";
import i18n from "i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { LanguagePicker } from "./LanguagePicker";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";

const items = [
  { label: "menu.home", url: "/" },
  { label: "menu.who-we-are", url: "/who-we-are" },
  { label: "menu.what-we-do", url: "/what-we-do" },
  { label: "menu.blog", url: "/blog" },
  { label: "menu.get-involved", url: "/get-involved" },
];

export const Menu = () => {
  const [isMenuExpanded, setIsMenuExpanded] =
    React.useState(false);

  const [isExpanded, setIsExpanded] = React.useState(false);

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, i18n.language]);

  const { t } = useTranslation();

  React.useEffect(() => {
    const clickHandler = (e) => {
      // basically, whereever the user clicks we have to hide the menu

      // CODE just in case we need it later on

      setIsMenuExpanded(false);

      // const $el = document.querySelector("#menu-options");
      // console.log($el);
      // console.log(e.target);

      // console.log($el.contains(e.target));
      // if ($el.contains(e.target)) {
      //   console.log("CLICKED INSIDE THE MENU");
      // } else {
      //   console.log("CLICKED OUTSIDE THE MENU");
      //   setIsMenuExpanded(false);
      // }
    };

    if (isMenuExpanded) {
      const $el = document.querySelector("#menu-options");

      document.addEventListener("click", clickHandler);
    }

    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, [isMenuExpanded]);

  return (
    <div>
      <Box className="desktop-page-menu">
        {items.map((item, index) => (
          <MenuItem
            {...item}
            key={index}
            isActive={pathname === item.url}
          />
        ))}
        <div className="menu-item menu-item-donate">
          {t("menu.donate")}
        </div>

        <LanguagePicker />
      </Box>
      <Box className="mobile-page-menu">
        {/* <LanguagePicker
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          setIsMenuExpanded={setIsMenuExpanded}
        /> */}
        <Button
          sx={{ width: "50px", height: "100%" }}
          onClick={(e) => {
            setIsExpanded(false);
            e.preventDefault();
            e.stopPropagation();
            setIsMenuExpanded(!isMenuExpanded);
          }}
        >
          <MenuIcon />
        </Button>
        {isMenuExpanded ? (
          <Box
            id="menu-options"
            sx={{
              position: "absolute",
              top: "80px",
              right: "0px",
              width: "170px",
              padding: "10px 0",
              background: "#FFFFFF",
              border: "1px solid #dedede",
            }}
          >
            {items.map((item, index) => (
              <MenuItem {...item} key={index} />
            ))}
            <div className="menu-item menu-item-donate">
              {t("menu.donate")}
            </div>
          </Box>
        ) : null}
        {/* <DonateNow />
      <LanguagePicker /> */}
      </Box>
    </div>
  );
};

const MenuItem = (props) => {
  const { label, url, isActive } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div
      className={`menu-item ${
        isActive ? "menu-item-active" : ""
      }`}
      onClick={() => {
        // console.log(url);
        navigate(url, { preventScrollReset: false });
      }}
    >
      {t(label)}
    </div>
  );
};
