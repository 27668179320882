import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use("ro")
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "ro",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      ro: {
        translation: {
          menu: {
            home: "Acasa",
            "who-we-are": "Cine suntem?",
            "what-we-do": "Ce facem?",
            "get-involved": "Implica-te!",
            donate: "Doneaza",
            blog: "Blog",
          },
          article: {
            seeFull: "vezi tot articolul",
          },
          form: {
            title: "Trimite-ne un mesaj!",
            placeholders: {
              firstName: "Prenume",
              lastName: "Nume",
              phone: "Telefon",
              email: "Email",
              message: "Mesaj",
            },
          },
          homepage: {
            "most-recent-articles":
              "Cele mai recente articole",
          },
        },
      },
      en: {
        translation: {
          menu: {
            home: "Home",
            "who-we-are": "Who we are?",
            "what-we-do": "What we do?",
            "get-involved": "Get involved!",
            donate: "Donate",
            blog: "Blog",
          },
          article: {
            seeFull: "read full article",
          },
          form: {
            title: "Send us a message!",
            placeholders: {
              firstName: "First name",
              lastName: "Last name",
              phone: "Phone number",
              email: "Email",
              message: "Message",
            },
          },
          homepage: {
            "most-recent-articles": "Most recent articles",
          },
        },
      },
    },
  });

export default i18n;
