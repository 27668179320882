import React from "react";

export const CookiesConsentModal = (props) => {
  const { Component } = props;
  const [isModalVisible, setIsModalVisible] =
    React.useState(false);

  const acceptCookies = () => {
    localStorage.setItem("cookie-consent", "yes");
    setIsModalVisible(false);
  };

  const declineCookies = () => {
    localStorage.setItem("cookie-consent", "no");
    setIsModalVisible(false);
  };

  const closeModalWithoutResponsing = () => {
    localStorage.setItem(
      "cookie-consent-ignored",
      Date.now()
    );
    setIsModalVisible(false);
  };

  React.useEffect(() => {
    const cookieConsent = localStorage.getItem(
      "cookie-consent"
    );

    const cookieConsentIgnored = localStorage.getItem(
      "cookie-consent-ignored"
    );

    if (!cookieConsentIgnored && !cookieConsent) {
      console.log("modal should be visible");
      setIsModalVisible(true);
    }
  }, [Component]);

  if (!isModalVisible) {
    return null;
  }

  return (
    <div className="cookies-consent-modal-wrapper">
      <div className="cookies-consent-modal">
        <div className="content">
          <div className="title">
            Acest site foloseste cookie-uri
          </div>

          <div className="text">
            Pentru a-ți oferi o experiență bună de navigare,
            utilizăm fișiere de tip cookie. Dacă nu ești de
            acord cu utilizarea cookie-urilor, poți să îți
            retragi consimțământul pentru utilizarea
            cookie-urilor prin modificarea setărilor din
            browser-ul tău.
          </div>

          <div className="buttons-wrapper">
            <div
              className="accept"
              onClick={() => {
                acceptCookies();
              }}
            >
              Sunt de acord
            </div>
            <div
              className="decline"
              onClick={() => {
                declineCookies();
              }}
            >
              Nu sunt de acord
            </div>
          </div>
        </div>
        <div
          className="close-button"
          onClick={() => {
            closeModalWithoutResponsing();
          }}
        >
          <CloseButton />
        </div>
      </div>
    </div>
  );
};

const CloseButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
    </g>
  </svg>
);
