import React from "react";

import pic3 from "../img/pic3.webp";
import transition from "../img/transition2.png";

import { Test } from "./Test";
import { Newsletter } from "./Newsletter";

import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import { useTranslation } from "react-i18next";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Homepage = (props) => {
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const [textOverImage, setTextOverImage] =
    React.useState("");
  const [textDescription, setTextDescription] =
    React.useState("");

  const [ourTeam, setOurTeam] = React.useState({});
  const [values, setValues] = React.useState({
    title: "",
    valuesItems: [],
  });

  const [articles, setArticles] = React.useState([]);

  const [getInvolved, setGetInvolved] = React.useState({
    title: "",
    description: "",
    buttonLabel: "",
  });

  const [statistics, setStatistics] = React.useState({
    title: "",
    statisticsItems: [],
  });

  const [mission, setMission] = React.useState({
    title: "",
    text: "",
  });

  const [mindLab, setMindLab] = React.useState({
    title: "",
    description: "",
    buttonLabel: "",
  });

  React.useLayoutEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/homepage?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        const {
          attributes: {
            textOverImage,
            textDescription,
            values,
            ourTeam,
            getInvolved,
            statistics,
            mindlab,
            mission,
          },
        } = data;

        setTextOverImage(textOverImage);
        setTextDescription(textDescription);
        setValues(values ?? {});
        setOurTeam(ourTeam ?? {});
        setGetInvolved(getInvolved ?? {});
        setStatistics(statistics ?? {});
        setMindLab(mindlab ?? {});
        setMission(mission ?? {});
      });

    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/articles?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        setArticles(
          data.reverse().filter((_, index) => index < 3)
        );
      });
  }, [i18n.language]);

  return (
    <div className="homepage-page">
      <div className="hero-section">
        <div className="hero-section-bg-image" />
        {textOverImage ? (
          <div className="message">{textOverImage}</div>
        ) : null}
      </div>

      <div className="transition-wrapper">
        <img src={transition} />
      </div>
      <div className="page-title-section">
        {textDescription}
      </div>
      <div className="transition-wrapper-bottom">
        <img src={transition} />
      </div>

      <div className="values-section">
        <div className="title-wrapper">{values.title}</div>
        <div className="values-wrapper">
          {values.valuesItems?.map(
            (
              {
                title,
                description,
                icon: {
                  data: {
                    attributes: { url },
                  },
                },
              },
              index
            ) => {
              return (
                <ValueItem
                  key={index}
                  title={title}
                  description={description}
                  icon={url}
                  index={index}
                  length={values.valuesItems.length}
                />
              );
            }
          )}
        </div>
      </div>

      <div className="page-title-section page-title-section-second">
        <div
          className="section-bg-image"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: "0px 30px 30px 0",
          }}
        />
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: "40px",
              margin: "5vh 0",
              color: "#3d5b59 ",
              textAlign: "center",
              width: "100%",
            }}
          >
            {mission.title}
          </div>
          <div
            style={{
              textAlign: "left",
              fontSize: " 18px",
              lineHeight: " 22px",
              color: "#3d5b59 ",
              textAlign: "center",
            }}
          >
            {mission.text}
          </div>
        </div>
      </div>
      {/* <div className="transition-wrapper-bottom">
        <img src={transition} />
      </div> */}

      {/* <div className="our-team-section">
        <div className="background-wrapper">
          <div className="background-gradient" />
        </div>
        <div className="section-bg-image"></div>
        <div className="title-wrapper">{ourTeam.title}</div>
        <div className="description-wrapper">
          {ourTeam.description}
        </div>
        <div className="button-wrapper">
          <Button
            variant="contained"
            sx={{
              background:
                "linear-gradient(145deg, #375250, #41615f) !important",
              color: "white",
              textTransform: "none",
              padding: "28px 22px",
              height: "40px",
            }}
          >
            {ourTeam.buttonLabel}
          </Button>
        </div>
      </div> */}

      <div className="our-team-section">
        <div className="background-gradient">
          <div className="section-bg-image" />
          <div className="title-wrapper">
            {ourTeam.title}
          </div>
          <div className="description-wrapper">
            {ourTeam.description}
          </div>
          <div className="button-wrapper">
            <Button
              variant="contained"
              sx={{
                background: "#9f6067 !important",
                color: "white",
                textTransform: "none",
                padding: "28px 64px",
                height: "40px",
              }}
              onClick={() => {
                navigate("/who-we-are");
              }}
            >
              {ourTeam.buttonLabel}
            </Button>
            {/* <Button
              variant="contained"
              sx={{
                background: "#9f6067 !important",
                color: "white",
                textTransform: "none",
                padding: "28px 64px",
                height: "40px",
              }}
              onClick={() => {
                navigate("/what-we-do");
              }}
            >
              {ourTeam.buttonLabel2}
            </Button> */}
          </div>
        </div>
      </div>

      <div className="mindlab-section">
        <div className="background-gradient">
          <div className="section-bg-image"></div>
          <div className="title-wrapper">
            {mindLab.title}
          </div>
          <div className="description-wrapper">
            {mindLab.description}
          </div>
          <div className="button-wrapper">
            {/* <Button
              variant="contained"
              sx={{
                background: "#9f6067 !important",
                color: "white",
                textTransform: "none",
                padding: "28px 64px",
                height: "40px",
              }}
              onClick={() => {
                navigate("/what-we-do");
              }}
            >
              {ourTeam.buttonLabel2}
            </Button> */}
            <Button
              variant="contained"
              sx={{
                background: "#9f6067 !important",
                color: "white",
                textTransform: "none",
                padding: "28px 64px",
                height: "40px",
              }}
              onClick={() => {
                navigate("/what-we-do");
              }}
            >
              {mindLab.buttonLabel}
            </Button>
          </div>
        </div>
      </div>

      <div className="donate-section">
        <div className="background-gradient">
          <div className="section-bg-image"></div>
          <div className="title-wrapper">
            {getInvolved.title}
          </div>
          <div className="description-wrapper">
            {getInvolved.description}
          </div>
          <div className="button-wrapper">
            <Button
              variant="contained"
              sx={{
                background: "#9f6067 !important",
                color: "white",
                textTransform: "none",
                padding: "28px 64px",
                height: "40px",
              }}
            >
              {getInvolved.buttonLabel}
            </Button>
          </div>
        </div>
      </div>

      <div className="statistics-section">
        <div className="title-wrapper">
          {statistics.title}
        </div>
        <div
          className={`statistics-wrapper statistics-count-${statistics.statisticsItems.length}`}
        >
          {statistics.statisticsItems?.map(
            (
              {
                description,
                icon: {
                  data: {
                    attributes: { url },
                  },
                },
              },
              index
            ) => {
              return (
                <Statistic
                  key={index}
                  description={description}
                  icon={url}
                />
              );
            }
          )}
        </div>
      </div>

      <div className="articles-section">
        <div className="title-wrapper">
          Cele mai recente articole
        </div>
        <div className="desktop-articles-wrapper">
          {articles.map((article, index) => {
            const url =
              article?.attributes?.image?.data?.attributes
                ?.formats?.small?.url;

            const highlights =
              article?.attributes?.highlights;

            const content = article?.attributes?.content;
            const title = article?.attributes?.title;
            const id = article?.attributes?.id;

            return (
              <Article
                id={id}
                key={index}
                image={url}
                title={title}
                highlights={highlights}
              />
            );
          })}
        </div>
        <div className="mobile-articles-wrapper">
          <Carousel
            draggable
            swipeable
            partialVisible
            responsive={{
              tablet: {
                breakpoint: { max: 1000, min: 500 },
                items: 2,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: { max: 500, min: 0 },
                items: 1,
                partialVisibilityGutter: 40,
              },
            }}
          >
            {articles.map((article, index) => {
              const url =
                article?.attributes?.image?.data?.attributes
                  ?.formats?.small?.url;

              const highlights =
                article?.attributes?.highlights;

              const content = article?.attributes?.content;
              const title = article?.attributes?.title;
              const id = article?.attributes?.id;

              return (
                <Article
                  id={id}
                  key={index}
                  image={url}
                  title={title}
                  highlights={highlights}
                />
              );
            })}
          </Carousel>
        </div>
        <div
          className="see-all-articles"
          onClick={() => {
            navigate("/blog");
          }}
        >{`vezi toate articolele >>`}</div>
      </div>
      <Newsletter />
    </div>
  );
};

const ValueItem = (props) => {
  const { icon, title, description, index, length } = props;
  return (
    <div
      className={`value-item index-${index} count-${length}`}
    >
      <div className="value-icon">
        <img src={icon} />
      </div>
      <div className="value-text">
        <div className="value-title">{title}</div>
        <div className="value-description">
          {description}
        </div>
      </div>
    </div>
  );
};

const Statistic = (props) => {
  const { description, icon } = props;

  return (
    <div className="statistic-item">
      <div className="icon-wrapper">
        <div
          style={{
            background: "#f6f6f6",
            padding: "0 10px",
            borderRadius: "10px",
          }}
        >
          <img src={icon} className="statistic-item-icon" />
        </div>
      </div>
      <div className="stats-description">{description}</div>
    </div>
  );
};

const Article = (props) => {
  const {
    id,
    image,
    title,
    highlights,
    customStyles = {},
  } = props;

  const navigate = useNavigate();

  return (
    <div className="article-wrapper">
      <div
        className="article-image"
        onClick={() => {
          navigate(`/article/${id}`);
        }}
      >
        <img src={image} />
      </div>
      <div className="article-content">
        <div className="article-title">
          {/* <div className="ellipsis-title"> */}
          {title}
          {/* </div> */}
        </div>
        <div className="article-description">
          {highlights}
        </div>
        <div
          className="article-see-more"
          onClick={() => {
            navigate(`/article/${id}`);
          }}
        >{`citeste tot articolul >>`}</div>
      </div>
    </div>
  );
};
