import React from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const ConfidentialityPolicy = () => {
  const [text, setText] = React.useState("");

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/confidentiality-policy`
    )
      .then((response) => response.json())
      .then(({ data }) => {
        // console.log(data);
        const value = data.attributes.text.toString();
        setText(value);
      });
  }, []);

  return (
    <div className="confidentiality-policy">
      <ReactMarkdown
        children={text}
        remarkPlugins={[remarkGfm]}
      />
    </div>
  );
};
