import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Test = () => {
  return (
    <Carousel
      responsive={{
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: { max: 1024, min: 0 },
          items: 3,
          partialVisibilityGutter: 40,
        },
      }}
    >
      <div>ONE</div>
      <div>two</div>
      <div>THREE</div>
    </Carousel>
  );
};
